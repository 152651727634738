import React from "react";

function Hero() {
  return (
    <div className="section hero" id="home">
      <div className="hero-overlay"></div>
      <img
        src="./hero_bg.png"
        alt="Bouldering background"
        className="hero-image"
      />
      <div className="hero-content">
        <h1>Rudy Kirk</h1>
        <span>Climber, Coach, Student, Content Creator</span>
      </div>
    </div>
  );
}

export default Hero;
