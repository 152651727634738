import React from "react";
import FooterLinks from "./FooterLinks";

function FooterNavBar() {
  return (
    <div className="footer-nav-bar">
      <FooterLinks
        iconName="ytlogosquare.webp"
        text="Youtube"
        link="https://www.youtube.com/@rudykirkclimbs"
      />
      <FooterLinks
        iconName="iglogo-nobg.png"
        text="Instagram"
        link="https://www.instagram.com/rudykirkclimbs/"
      />
      <FooterLinks iconName="threadslogo-nobg.png" text="Threads" link="  " />
    </div>
  );
}

export default FooterNavBar;
