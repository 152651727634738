import React from "react";

function Newsletter() {
  return (
    <div className="section newsletter" id="newsletter">
      <h2>Rudy Kirk, Weekly.</h2>
      <p>Sign up for my weekly newsletter</p>
      <a
        href={"https://rudykirkweekly.beehiiv.com/"}
        className="course-news-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>rudykirkweekly</span>
        <img
          src="beehiv.jpeg"
          alt="beehiiv logo"
          className="course-news-link-image"
        />
      </a>
    </div>
  );
}

export default Newsletter;
