import Body from "./Body";
// import Newsletter from "./Newsletter";
import NavigationBar from "./NavigationBar";

function App() {
  return (
    <div className="app">
      <NavigationBar />
      <Body />
      {/* <Newsletter /> */}
    </div>
  );
}

export default App;
