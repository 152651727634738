import React from "react";

function FooterLinks({ iconName, text, link }) {
  return (
    <div className="footer-links">
      <a
        href={link}
        className="footer-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={iconName} alt={text} className="icons" />
      </a>
    </div>
  );
}

export default FooterLinks;
