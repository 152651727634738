import React from "react";
import FooterLinks from "./FooterLinks";

function Footer() {
  return (
    <div className={`footer`}>
      <FooterLinks
        iconName="ytlogosquare.webp"
        text="Youtube"
        link="https://www.youtube.com/@rudykirkclimbs"
      />
      <FooterLinks
        iconName="iglogo-nobg.png"
        text="Instagram"
        link="https://www.instagram.com/rudykirkclimbs/"
      />
      <FooterLinks
        iconName="threadslogo-nobg.png"
        text="Threads"
        link="https://www.threads.net/@rudykirkclimbs"
      />
    </div>
  );
}

export default Footer;
