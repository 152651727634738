import React from "react";
import Hero from "./Hero";
import Course from "./Course";
import Footer from "./Footer";
// import RecentPosts from "./RecentPosts";
import About from "./About";
import Newsletter from "./Newsletter";

function Body() {
  return (
    <div>
      <Hero />
      <Course />
      <About />
      {/* <RecentPosts /> */}
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Body;
