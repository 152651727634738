import React from "react";
import FooterNavBar from "./FooterNavBar";

function NavigationBar() {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <div className="nav-bar">
      <a href="https://rudykirkclimbs.com" className="link-div">
        <div className="left-content">
          <img src="/me.jpeg" alt="me" className="image" />
          Rudy Kirk Climbs
        </div>
      </a>
      <div className="buttons">
        <button onClick={() => scrollToSection("home")}>Home</button>
        <button onClick={() => scrollToSection("course")}>Course</button>
        <button onClick={() => scrollToSection("about")}>About</button>
        <button onClick={() => scrollToSection("newsletter")}>News</button>
      </div>
      <FooterNavBar />
    </div>
  );
}

export default NavigationBar;
