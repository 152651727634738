import React from "react";

function Course() {
  return (
    <div className="section course" id="course">
      <div className="course-header">
        <h1>COURSES ARE ON THE WAY</h1>
        <p>
          Want to know the second they release? Join my weekly newsletter for
          news and more!
        </p>
      </div>
      <div>
        <a
          href={"https://rudykirkweekly.beehiiv.com/"}
          className="course-news-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>rudykirkweekly</span>
          <img
            src="beehiv.jpeg"
            alt="beehiiv logo"
            className="course-news-link-image"
          />
        </a>
      </div>
    </div>
  );
}

export default Course;
