import React from "react";

function About() {
  const timeSinceMarch2023 = () => {
    const calculateTimeSince = () => {
      const startDate = new Date("2023-03-01");
      const currentDate = new Date();

      // Calculate the difference in milliseconds
      const diffTime = currentDate - startDate;

      // Convert milliseconds to days
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      // Calculate years and remaining days
      const years = diffDays / 365; // Use decimal for years
      const wholeYears = Math.floor(years);
      const decimalPart = years - wholeYears;

      // Determine if there are more than half a year
      const displayYears = wholeYears + (decimalPart > 0.5 ? 1 : 0);

      return { displayYears, decimalPart };
    };

    const numberToWords = (num) => {
      const ones = [
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ];
      const tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];

      if (num < 20) return ones[num];
      if (num < 100)
        return (
          tens[Math.floor(num / 10)] +
          (num % 10 > 0 ? " " + ones[num % 10] : "")
        );
      if (num < 1000)
        return (
          ones[Math.floor(num / 100)] +
          " hundred" +
          (num % 100 > 0 ? " and " + numberToWords(num % 100) : "")
        );

      return num.toString(); // Fallback for larger numbers
    };

    const { displayYears, decimalPart } = calculateTimeSince();

    return `${
      decimalPart > 0.5
        ? numberToWords(displayYears - 1)
        : numberToWords(displayYears)
    }${" "} ${decimalPart > 0.5 ? " and a half" : ""} year${
      displayYears !== 1 ? "s" : ""
    } ago`;
  };

  return (
    <div className="section aboutme" id="about">
      <div className="aboutme-content">
        <h1>Who is Rudy?</h1>
        <div className="aboutme-inline-text">
          <span>Climber, Coach, Student, Content Creator... </span>
          <h2>But what else?</h2>
        </div>
        <p>
          <b>I'm a climber, just like you!</b> I started climbing{" "}
          {timeSinceMarch2023()}, initially with the intention of climbing
          irregularly and just for fun. But that quickly changed with my first
          project, a 5.9 named Lemon Peppa Steppas. The route was well set,
          where a little technique went a long way, and the journey of sending
          it hooked me on climbing! Although my love for climbing began on a
          small wall in the corner of a college campus, my passion for the sport
          is big, and I want to share it with the world! Now, I’m on the wall at
          every opportunity, dedicated to inspiring others to climb with
          confidence and community.
          <br />
          <br />
          My love for teaching has led me to work with climbers of all ages and
          skill levels. Now, I’m excited to combine that passion with climbing
          by helping beginners and intermediate climbers level up their skills.
          Climbing, to me, is not just about reaching new heights but also
          fostering a supportive community. It was the encouragement from those
          around me that pushed me to break through my limits and tackle harder
          routes.
          <br />
          <br />
          <b>
            By subscribing to rudykirkclimbs, you’re joining a community
            dedicated to uplifting one another and conquering greater heights
            together.
          </b>
          <br />
          <br />
          In the coming years, I aim to bring valuable content to my social
          media platforms while growing a vibrant climbing community. Whether
          you’re on your first route or pushing for new grades, I’ll be here
          with guidance, tips, and encouragement. Expect exclusive courses,
          strategies for overcoming plateaus, and weekly updates packed with
          tips, tricks, and upcoming events.
          <br />
          <br />
          What’s ahead? Courses, community, and content! I’ll soon be launching
          courses covering everything you need to know about bouldering—from the
          basics to advanced techniques. Plus, each week you’ll get climbing
          tips, community updates, and insider advice through my newsletter.
          <br />
          <br />
          Fun fact: My favorite climbing memory so far is sending my first V6
          project.
        </p>
      </div>
    </div>
  );
}
export default About;
